import notify from "./modules/notify.js"
import menu from "./modules/menu.js"
import net from "./modules/network.js"
import cf from "./modules/common-func.js"

import Base64 from "base-64"

jQuery(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const loginButton = $("#login-btn")
    const loginReturnButton = $("#login-return")
    const loginErrorDisplay = $(".login-error")
    const errorClass = "error-show"

    if (urlParams.get("error")) {
        loginErrorDisplay.addClass(errorClass)
        window.history.pushState("", "", "/login")
    }

    loginButton.on("click", async () => {
        net.load("#login-btn", true, false, true)

        const email = $("#login_username").val()
        const password = Base64.encode($("#login_password").val())

        const successClass = "login-success"
        const loginContainer = $(".login-container")

        try {
            const res = await net.request("POST", `/auth/local?email=${email}&password=${password}`)
            loginContainer.addClass("login-flashout")
            loginButton.addClass(successClass)
            loginErrorDisplay.removeClass(errorClass)
            loginReturnButton.css("opacity", 0)

            loginButton.on("animationend", () => {
                window.location.replace(urlParams.get("fallbackUrl") || "/login")
            })
        } catch(err) {
            console.log(err)
            loginErrorDisplay.addClass(errorClass)
        }

        net.finishLoad()
    })

    $(".login__link").on("click", async () => {
        const html = await net.template("PasswordRecovery")
        menu.create("Password Recovery")
        menu.setWindowClass("window-auto")
        await menu.setRenderHTML(html)

        cf.trackEmailAndPhones()

        $("#btn-request").on("click", async () => {
            const recoveryEmailInput = $("#recovery_email")
            const email = recoveryEmailInput.val()
            const isError = recoveryEmailInput.hasClass("input-warning")
            if (isError || !email || email === "") {
                return
            }

            net.load()
            const res = await net.request("POST", "/account/newpassword?email=" + email)
            res.receive(() => {
                notify.success("An email was sent to the specified address with recovery link!", 5)
                menu.close()
            }, {
                406: "There is no account attached to this email!",
                502: "Previous link have not expired yet!"
            })

            net.finishLoad()
        })
    })

    $(document).on("keypress", function(e) {
        if (e.key !== "Enter") {
            return
        }

        loginButton.trigger("click")
    })

    // const screenWidth =  $(window).outerWidth(),
    //     screenHeight = $(window).outerHeight();

    // for (let i = 0; i < 200; i++) {
    //     const ballsContainer = $("#balls")
    //     const opacity = cf.randomNumber(0.01, 0.1)

    //     const ballSize = cf.randomNumber(10, 30)

    //     const leftSide = [-1, 1][Math.round(cf.randomNumber(0, 1))],
    //         top = cf.randomNumber(0, screenHeight);

    //     const left = screenWidth / 2 + (screenWidth / 2 * leftSide) + ballSize * leftSide

    //     ballsContainer.append(`
    //         <div class="balls__particle" style="left: ${left}px; top: ${top}px; width: ${ballSize}px; height: ${ballSize}px;"></div>    
    //     `)

    //     const item = $(".balls__particle").last()

    //     item.on("mouseenter", function() {
    //         ballsContainer.append(`
    //             <div class="balls__particle-shadow" style="left: ${item.offset().left}px; top: ${item.offset().top}px; width: ${ballSize}px; height: ${ballSize}px;"></div>        
    //         `)

    //         const lastShadow = $(".balls__particle-shadow").last()

    //         lastShadow.on("animationend", () => {
    //             lastShadow.remove()
    //         })

    //         $(this).remove()
    //     })
            
    //     item.animate({
    //         opacity: opacity
    //     }, 1000)

    //     item.animate({
    //         left: (left > screenWidth / 2) ? 0 - ballSize : screenWidth + ballSize,
    //         top: (top > screenHeight / 2) ? cf.randomNumber(0, screenHeight / 2) : cf.randomNumber(screenHeight / 2, screenHeight)
    //     }, cf.randomNumber(25, 50) * 1000)
    // }
})